import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AppState = {
  invocationIsValid: boolean;
  NFTKey: string;
};

const initialState: AppState = {
  // invocationIsValid: true,
  invocationIsValid: false,
  // NFTKey: "b09a72dq", // mies
  // NFTKey: "g6t74f23", // reis
  NFTKey: "",
};

export const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInvocationIsValid: (state) => {
      state.invocationIsValid = true;
    },
    setNFTKey: (state, action: PayloadAction<string>) => {
      state.NFTKey = action.payload;
    },
  },
});

export const appReducer = app.reducer;
export const { setInvocationIsValid, setNFTKey } = app.actions;
