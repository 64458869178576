import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "../../../state/hooks";
import { RootState } from "../../../state/store";

interface Props {}

const SwipeMessage = (props: Props) => {
  const swipeMessages = useAppSelector((state: RootState) => state.swipe.swipeMessages);

  return (
    // set height to prevent message to grow too large
    <div className="absolute top-0 left-0 w-screen h-64 overflow-hidden text-white">
      <ul>
        <AnimatePresence exitBeforeEnter>
          {swipeMessages.map(
            (swipeMessage, index, messages) =>
              index === messages.length - 1 && (
                <motion.li
                  className="absolute top-0 left-0 w-screen"
                  key={index}
                  initial={{ x: "100%" }}
                  animate={{ x: "0%", transition: { duration: 0.5 } }}
                  exit={{ x: "-100%", transition: { duration: 0.5 } }}
                >
                  <div className="mx-4 mt-4 mb-2 text-lg font-bold text-center whitespace-pre-wrap">{swipeMessage.title}</div>
                  <div className="mx-4 my-2 text-center whitespace-pre-wrap">{swipeMessage.message}</div>
                </motion.li>
              )
          )}
        </AnimatePresence>
      </ul>
    </div>
  );
};

export default SwipeMessage;
