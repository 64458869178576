import { motion } from "framer-motion";
import { useAppSelector } from "../../../state/hooks";
import { RootState } from "../../../state/store";

const ProgressBar = () => {
  const progress = useAppSelector((state: RootState) => state.swipe.progress);

  return (
    <motion.div
      className="absolute bottom-0 flex w-screen overflow-hidden bg-soulmate-green/70 touch-none"
      animate={{ height: `${progress > 5 ? progress : 0}%` }}
    ></motion.div>
  );
};

export default ProgressBar;
