import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectivityStatus } from "@prismadelabs/prismaid";
import i18n from "../../translations/i18n";

export type SwipeMessage = {
  title: string;
  message: string;
};

export type SwipeState = {
  connectivityStatus: ConnectivityStatus;
  progress: number;
  isActive: boolean;
  scaleFactor: number;
  swipeMessages: SwipeMessage[];
  imageUrl: string;
  redirectUrl: string;
};

const initialState: SwipeState = {
  connectivityStatus: ConnectivityStatus.ok,
  progress: 0,
  isActive: false,
  scaleFactor: 0,
  swipeMessages: [
    {
      title: i18n.t("swipe:place.title"),
      message: i18n.t("swipe:place.body"),
    },
  ],
  imageUrl: "",
  redirectUrl: "",
};

export const swipe = createSlice({
  name: "swipe",
  initialState,
  reducers: {
    setConnectivityStatus: (state, action: PayloadAction<ConnectivityStatus>) => {
      state.connectivityStatus = action.payload;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setScaleFactor: (state, action: PayloadAction<number>) => {
      state.scaleFactor = action.payload;
    },
    requestInitialTouchMessage: (state) => {
      if (state.swipeMessages.length === 1) {
        state.swipeMessages.push({
          title: i18n.t("swipe:swipe.title"),
          message: i18n.t("swipe:swipe.body"),
        });
      }
    },
    addSwipeMessage: (state, action: PayloadAction<SwipeMessage>) => {
      state.swipeMessages.push(action.payload);
    },
    resetSwipeMessage: (state) => {
      state.swipeMessages = [
        {
          title: i18n.t("swipe:place.title"),
          message: i18n.t("swipe:place.body"),
        },
      ];
    },
    setImageUrl: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    },
  },
});

export const swipeReducer = swipe.reducer;
export const {
  setConnectivityStatus,
  setProgress,
  setIsActive,
  setScaleFactor,
  requestInitialTouchMessage,
  addSwipeMessage,
  resetSwipeMessage,
  setImageUrl,
  setRedirectUrl,
} = swipe.actions;
