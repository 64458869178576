import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import common_de from "./de/common.json";
import faq_de from "./de/faq.json";
import invalid_de from "./de/invalid.json";
import swipe_de from "./de/swipe.json";
import touch_de from "./de/touch.json";

import common_en from "./en/common.json";
import faq_en from "./en/faq.json";
import invalid_en from "./en/invalid.json";
import swipe_en from "./en/swipe.json";
import touch_en from "./en/touch.json";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      de: {
        common: common_de,
        faq: faq_de,
        invalid: invalid_de,
        swipe: swipe_de,
        touch: touch_de,
      },
      en: {
        common: common_en,
        faq: faq_en,
        invalid: invalid_en,
        swipe: swipe_en,
        touch: touch_en,
      },
    },

    detection: { order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"] },
    fallbackLng: "en",
    supportedLngs: ["de", "en"],

    ns: ["common"],
    defaultNS: "common",

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "u", "b"],
    },

    debug: process.env.NODE_ENV === "development",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
