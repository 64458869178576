import { useTranslation } from "react-i18next";
import Modal from "../../../../components/layout/Modal";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

const DeviceNotSupportedModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Modal title={t("invalid:invalidDevice.title")} isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
      <p className="mt-4 whitespace-pre-wrap">{t("invalid:invalidDevice.body")}</p>
    </Modal>
  );
};

export default DeviceNotSupportedModal;
