import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import CloseButton from "../../components/UI/buttons/CloseButton";
import HelpButton from "../../components/UI/buttons/HelpButton";
import { useAppSelector } from "../../state/hooks";
import { RootState } from "../../state/store";
import NetworkIndicator from "./NetworkIndicator";
import ProgressBar from "./ProgressBar";
import SwipeField from "./SwipeField";
import SwipeMessage from "./SwipeMessage";

const Swipe = () => {
  let navigate = useNavigate();

  const invocationIsValid = useAppSelector((state: RootState) => state.app.invocationIsValid);

  useEffect(() => {
    if (invocationIsValid) {
      return;
    }
    navigate("/app", { replace: true });
  }, []);

  return (
    <MotionDiv className="h-full">
      <div className="h-full">
        <SwipeField />
        <ProgressBar />
        <NetworkIndicator />
        <SwipeMessage />
        <div className="absolute top-0 left-0 z-40">
          <CloseButton onClick={() => navigate("/tutorial", { replace: true })} />
        </div>
        <div className="absolute top-0 right-0 z-40">
          <HelpButton onClick={() => navigate("/faq", { replace: true })} />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Swipe;
