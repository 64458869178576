import { AnimatePresence } from "framer-motion";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import WithInvocationCheck from "./components/layout/WithInvocationCheck";
import WithOrientationNotice from "./components/layout/WithOrientationNotice";
import WithSessionExpiredWarning from "./components/layout/WithSessionExpiredWarning";
import { isValidCard } from "./helper/isValidCard";

import Failure from "./pages/Failure";
import FAQ from "./pages/Faq";
import Start from "./pages/Start";
import Success from "./pages/Success";
import Swipe from "./pages/Swipe";
import Tutorial from "./pages/Tutorial";

export function isValidStartPath(path: string) {
  const validStartPaths = ["/", "/app"];

  // slice beginning / from path segment to get id
  return validStartPaths.includes(path) || isValidCard(path.slice(1));
}

const RouterComponent = () => {
  const location = useLocation();

  return (
    <div className="w-full h-full bg-gradient-default">
      <WithSessionExpiredWarning />
      <AnimatePresence exitBeforeEnter>
        <WithOrientationNotice>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Navigate to="/app" />} />
            <Route path="/:nftKey" element={<Start />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/swipe" element={<Swipe />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/success" element={<Success />} />
            <Route path="/failure" element={<Failure />} />
          </Routes>
        </WithOrientationNotice>
      </AnimatePresence>
    </div>
  );
};

export default RouterComponent;
