import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import Sound from "../../components/Sound";
import BackButton from "../../components/UI/buttons/BackButton";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { resetSwipeMessage } from "../../state/slices/swipe";
import { RootState } from "../../state/store";

import bg from "../../assets/img/00_general/bg.jpg";
import click from "../../assets/sounds/klick.mp3";

type Props = {
  summary: string;
  children: ReactNode;
};

function FaqDetails(props: Props) {
  return (
    <details className="mt-4">
      <summary className="pl-2 text-lg font-semibold">
        <span className="ml-2">{props.summary}</span>
      </summary>
      <div className="mx-7">{props.children}</div>
    </details>
  );
}

function FAQ() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const clickSound = new Sound(click);

  const invocationIsValid = useAppSelector((state: RootState) => state.app.invocationIsValid);

  useEffect(() => {
    if (invocationIsValid) {
      return;
    }
    navigate("/app", { replace: true });
  }, []);

  return (
    <MotionDiv className="h-full text-white">
      <div className="w-screen h-full pb-8 bg-center bg-cover" style={{ backgroundImage: `url(${bg})` }}>
        <div className="flex items-center justify-center w-full">
          <BackButton
            onClick={() => {
              clickSound.play();
              dispatch(resetSwipeMessage());
              navigate("/swipe", { replace: false });
            }}
            className="absolute top-0 left-0"
          />
          <div className="flex items-center justify-center w-full h-16 text-lg font-semibold">{t("faq:title")}</div>
        </div>

        <div className="w-full h-full p-4 pb-12 space-y-4 overflow-scroll prose text-left">
          <p>{t("faq:intro")}</p>
          <FaqDetails summary={t("faq:q1")}>
            <p>{t("faq:a1_1")}</p>
            <ul>
              <li>{t("faq:a1_2")}</li>
              <li>{t("faq:a1_3")}</li>
              <li>{t("faq:a1_4")}</li>
              <li>{t("faq:a1_5")}</li>
              <li>{t("faq:a1_6")}</li>
              <li>{t("faq:a1_7")}</li>
            </ul>
          </FaqDetails>

          <FaqDetails summary={t("faq:q2")}>
            <p>{t("faq:a2")}</p>
          </FaqDetails>

          <FaqDetails summary={t("faq:q3")}>
            <p>{t("faq:a3")}</p>
          </FaqDetails>

          <FaqDetails summary={t("faq:q4")}>
            <p>{t("faq:a4")}</p>
          </FaqDetails>

          <FaqDetails summary={t("faq:q5")}>
            <p>{t("faq:a5")}</p>
          </FaqDetails>
        </div>
      </div>
    </MotionDiv>
  );
}

export default FAQ;
