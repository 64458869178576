import Button from "../../../../components/UI/buttons/Button";
import Modal from "../../../../components/layout/Modal";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

const ShouldAddToHomeScreenModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Modal title={t("invalid:screenTooSmall.title")} isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
      <p className="mt-4 whitespace-pre-wrap">{t("invalid:screenTooSmall.body")}</p>
      <div className="mt-6">
        <Button title={t("invalid:screenTooSmall.proceedAnyway")} onClick={() => props.setIsOpen(false)} inverted={true} />
      </div>
    </Modal>
  );
};

export default ShouldAddToHomeScreenModal;
