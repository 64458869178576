import { useTranslation } from "react-i18next";
import SensitivityModal from "./SensitivityModal";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
}

const GloveModeModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <SensitivityModal
      title={t("touch:title_glovemode")}
      p1={t("touch:p1_glovemode")}
      p2={t("touch:p2_glovemode")}
      p3={t("touch:p3_glovemode")}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
    />
  );
};

export default GloveModeModal;
