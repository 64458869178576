import { useTranslation } from "react-i18next";
import SensitivityModal from "./SensitivityModal";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
}

const TouchSensitivityModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <SensitivityModal
      title={t("touch:title_touchSensitivity")}
      p1={t("touch:p1_touchSensitivity")}
      p2={t("touch:p2_touchSensitivity")}
      p3={t("touch:p3_touchSensitivity")}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
    />
  );
};

export default TouchSensitivityModal;
