import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import { useAppSelector } from "../../state/hooks";
import { RootState } from "../../state/store";
import { useTranslation } from "react-i18next";

import cross from "../../assets/img/04_verify/Crossbutton.json";

const Failure = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const nftKey = useAppSelector((state: RootState) => state.app.NFTKey);

  const staggerFadeIn = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.7,
        delayChildren: 0.5,
        staggerChildren: 0.5,
      },
    },
  };

  const [playing, setPlaying] = useState(false);

  const invocationIsValid = useAppSelector((state: RootState) => state.app.invocationIsValid);

  useEffect(() => {
    if (invocationIsValid) {
      return;
    }
    navigate("/app", { replace: true });
  }, []);

  return (
    <MotionDiv className="h-full">
      <div className="flex flex-col items-center justify-between h-full px-8 pt-8 pb-8 text-center text-white">
        <div className="">
          <div
            className="text-2xl font-medium"
            dangerouslySetInnerHTML={{
              __html: t("failure.p1"),
            }}
          />

          <div
            className="mt-8 text-lg leading-snug"
            dangerouslySetInnerHTML={{
              __html: t("failure.p2"),
            }}
          />
        </div>

        <motion.div variants={staggerFadeIn} initial="hidden" animate="show" className="flex flex-col-reverse overflow-hidden">
          <motion.div variants={staggerFadeIn} className="flex flex-col items-center text-lg" onAnimationComplete={() => setPlaying(true)}>
            <Lottie animationData={cross} play={playing} loop={false} speed={0.6} className="w-[14rem] mx-auto h-[14rem] -m-20" />
          </motion.div>
        </motion.div>

        <div className="w-full mt-8">
          <div className="px-6">
            <Button onClick={() => navigate(`/${nftKey}`, { replace: true })} title={t("failure.retry")} className="w-full mx-auto" />
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Failure;
