import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import MotionDiv, { MotionDivVariant } from "../../components/motion/MotionDiv";
import BackButton from "../../components/UI/buttons/BackButton";
import Button from "../../components/UI/buttons/Button";
import Card from "../../components/UI/Card";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { resetSwipeMessage } from "../../state/slices/swipe";
import { RootState } from "../../state/store";

import instruction1 from "../../assets/img/02_tutorial/01_welcome-NFT.gif";
import instruction2 from "../../assets/img/02_tutorial/02_placement-NFT.gif";
import instruction3 from "../../assets/img/02_tutorial/03_swipe-NFT.gif";

const Tutorial = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const nftKey = useAppSelector((state: RootState) => state.app.NFTKey);

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderRef = useRef<any>();

  const dotVariants = {
    full: { width: 15, height: 15, backgroundColor: "#FFFFFFFF", opacity: 0.8 },
    empty: { width: 8, height: 8, backgroundColor: "#FFFFFF", opacity: 0.3 },
  };

  const invocationIsValid = useAppSelector((state: RootState) => state.app.invocationIsValid);

  useEffect(() => {
    if (invocationIsValid) {
      return;
    }
    navigate("/app", { replace: true });
  }, []);

  return (
    <MotionDiv className="h-full">
      <div className="flex flex-col items-center justify-between w-full h-full pt-10 pb-8 text-white bg-gradient-default">
        <div className="absolute top-0 z-20 flex items-center justify-between w-screen">
          <BackButton
            onClick={() => {
              if (currentSlide === 0) {
                navigate(`/${nftKey}`, { replace: true });
              } else {
                sliderRef.current.slickGoTo(currentSlide - 1);
              }
            }}
          />
          <button
            onClick={() => {
              dispatch(resetSwipeMessage());
              navigate("/swipe", { replace: true });
            }}
            className="px-6 py-4 font-medium text-l"
          >
            {t("tutorial.skip")}
          </button>
        </div>

        <div className="relative flex grow">
          <div className="absolute w-screen overflow-hidden">
            <div className="flex flex-col items-center justify-center flex-grow w-screen">
              <AnimatePresence exitBeforeEnter>
                {currentSlide === 0 && (
                  <MotionDiv
                    initial={MotionDivVariant.InitialHidden}
                    animate={MotionDivVariant.FadeInFast}
                    exit={MotionDivVariant.FadeOutFast}
                    key="image1"
                    className="flex-grow w-screen h-full bg-center bg-no-repeat bg-contain"
                  >
                    <img src={instruction1} alt="" className="z-0 w-full h-full overflow-hidden image-contain" />
                  </MotionDiv>
                )}

                {currentSlide === 1 && (
                  <MotionDiv
                    initial={MotionDivVariant.InitialHidden}
                    animate={MotionDivVariant.FadeInFast}
                    exit={MotionDivVariant.FadeOutFast}
                    key="image2"
                    className="flex-grow w-screen bg-center bg-no-repeat bg-contain"
                  >
                    <img src={instruction2} alt="" className="w-full h-full overflow-hidden image-contain" />
                  </MotionDiv>
                )}
                {currentSlide === 2 && (
                  <MotionDiv
                    initial={MotionDivVariant.InitialHidden}
                    animate={MotionDivVariant.FadeInFast}
                    exit={MotionDivVariant.FadeOutFast}
                    key="image3"
                    className="flex-grow w-screen bg-center bg-no-repeat bg-contain"
                  >
                    <img src={instruction3} alt="" className="w-full h-full overflow-hidden image-contain" />
                  </MotionDiv>
                )}
              </AnimatePresence>
            </div>
          </div>

          <div className="mt-auto">
            <Slider
              dots={false}
              arrows={false}
              centerMode={true}
              className="w-screen"
              infinite={false}
              afterChange={(index) => setCurrentSlide(index)}
              ref={sliderRef}
            >
              <Card small className="flex items-center justify-center h-20 mx-2 text-sm leading-snug tutorialCard bg-white/30">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("tutorial.slide1"),
                  }}
                />
              </Card>
              <Card small className="flex items-center justify-center h-20 mx-2 text-sm leading-snug tutorialCard bg-white/30">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("tutorial.slide2"),
                  }}
                />
              </Card>
              <Card small className="flex items-center justify-center h-20 mx-2 text-sm leading-snug tutorialCard bg-white/30">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("tutorial.slide3"),
                  }}
                />
              </Card>
            </Slider>
          </div>
        </div>

        <div className="flex items-center h-8 my-2 space-x-3">
          <AnimatePresence>
            <motion.div
              key="dot1"
              className="border-2 border-white rounded-full"
              variants={dotVariants}
              animate={currentSlide === 0 ? "full" : "empty"}
              transition={{ type: "linear" }}
            ></motion.div>
            <motion.div
              key="dot2"
              className="border-2 border-white rounded-full"
              variants={dotVariants}
              animate={currentSlide === 1 ? "full" : "empty"}
              transition={{ type: "linear" }}
            ></motion.div>
            <motion.div
              key="dot3"
              className="border-2 border-white rounded-full"
              variants={dotVariants}
              animate={currentSlide === 2 ? "full" : "empty"}
              transition={{ type: "linear" }}
            ></motion.div>
          </AnimatePresence>
        </div>

        <div className="z-20 w-full px-8">
          <div className="px-6">
            <Button
              title={currentSlide === 2 ? t("tutorial.start") : t("tutorial.next")}
              onClick={() => {
                if (currentSlide === 2) {
                  dispatch(resetSwipeMessage());
                  navigate("/swipe", { replace: true });
                } else {
                  sliderRef.current.slickGoTo(currentSlide + 1);
                }
              }}
              className="w-full"
            />
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Tutorial;
